import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ComingSoonPage = () => (
  <Layout>
    <SEO title="Coming Soon" />
    <div className="mx-auto w-full max-w-6xl text-center">
      <div className="my-10">
        <h1>Coming Soon</h1>
        <p>TBD: To Be Developed</p>
      </div>
    </div>
  </Layout>
)

export default ComingSoonPage
